import React from "react";
import { SearchModal } from './Search';
import { StationDetailModal } from './StationDetail';
import { CheckUserModal } from "./CheckUserModal";
import { MailVerificationToast } from "./MailVerificationToast";
import {ParkDetailModal} from "./ParkDetail";

const Modals = ({map}) => {
    return (
        <>
            <StationDetailModal />
            <SearchModal map={map} />
            <CheckUserModal/>
            <MailVerificationToast/>
            <ParkDetailModal />
        </>
    )
}

export default Modals;
