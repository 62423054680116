//Components
import {TGButton, TGIcon, TGText} from '../TGElements'
//Helpers
import uniqid from 'uniqid'
import {useTranslation} from 'react-i18next';
//Redux
import {useDispatch, useSelector} from 'react-redux';
import {setActionSuccessModal, setCardActionsModal} from '../../redux/slices/modalSlice';
import {useDialog} from '../../hooks';
import {getPaymentMethods, removeCreditCard, setDefaultCard} from '../../helpers';
import {Alert} from '../../helpers/alert';
import {IonButtons, IonHeader, IonModal, IonToolbar} from "@ionic/react";
import DeleteCardAnimation from "./DeleteCardAnimation";

const CardActionModal = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const {status, selectedCard} = useSelector(state => state.modal.cardActionsModal)
  const actionList = [
    {id: uniqid(), slug: "make-default-card", notVisible: selectedCard?.default},
    {id: uniqid(), slug: "remove-card"},
  ];
  const showDialog = useDialog()
  const handleAction = item => {
    if (item.slug === "remove-card") {
      handleRemoveCard()
    } else {
      handleSetDefaultCard()
    }
  }

  const handleSetDefaultCard = async () => {
    if (!selectedCard.default) {
      const res = await setDefaultCard(selectedCard.id)
      if (res.success) {
        Alert.success(t("default-card-success", {
          last4: selectedCard?.card?.last4
        }))
        dispatch(
          setActionSuccessModal({
            status: true,
            action: "set-default",
            successText: t("default-card-success", {
              last4: selectedCard?.card?.last4
            }),
          })
        );
        getPaymentMethods()
        dispatch(setCardActionsModal({status: false, selectedCard: null}))
      }
    } else {
      Alert.warning(t("default-card-warning", {
        last4: selectedCard?.card?.last4
      }))
      dispatch(setCardActionsModal({status: false, selectedCard: null}))
    }
  }

  const confirmHandler = async () => {
    const res = await removeCreditCard(selectedCard.id);
    if (res.success) {
      dispatch(
        setActionSuccessModal({
          status: true,
          action: "delete-card",
          successText: t("remove-card-success", {
            last4: selectedCard?.card?.last4
          })
        })
      );
      Alert.success(t("remove-card-success", {
        last4: selectedCard?.card?.last4
      }))
      getPaymentMethods()
      dispatch(setCardActionsModal({status: false, selectedCard: null}))
    }
  }

  const handleRemoveCard = () => {
    const confirmHandler = async () => {
      const res = await removeCreditCard(selectedCard.id);
      if (res.success) {
        dispatch(
          setActionSuccessModal({
            status: true,
            action: "delete-card",
            successText: t("remove-card-success", {
              last4: selectedCard?.card?.last4
            })
          })
        );
        Alert.success(t("remove-card-success", {
          last4: selectedCard?.card?.last4
        }))
        getPaymentMethods()
        dispatch(setCardActionsModal({status: false, selectedCard: null}))
      }
    }
    const cancelHandler = () => dispatch(setCardActionsModal({status: false, selectedCard: null}))
    showDialog(`remove-card-dialog`, "yes", confirmHandler, "no", cancelHandler)
  }

  const closeHandler = () => {
    dispatch(setCardActionsModal({status: false, selectedCard: null}))
  }

  return (
    <IonModal
      isOpen={status}
      onIonModalWillDismiss={closeHandler}
      breakpoints={[0, 1]}
      initialBreakpoint={1}
      style={{'--height': 'auto'}}
    >

      <IonHeader className="ion-no-border ion-padding-start ion-padding-end">
        <IonToolbar className="TGHeaderToolbar ion-padding-top">
          <IonButtons slot="end">
            <TGIcon name={"close-modal"} size={30} onClick={closeHandler}/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <div className={'ion-padding-start ion-padding-end CardActionModal'}>

        <DeleteCardAnimation/>

        <TGText
          color="var(--tg-primary-text)"
          fontSize={20}
          fontWeight="semibold"
          textAlign="center"
          width="100%"
          margin={"0 0 17px 0"}
        >
          {t("remove-my-card")}
        </TGText>
        <TGText
          color={"#191E26"}
          fontSize={16}
          margin={"0 0 25px 0"}
          fontWeight="medium"
          textAlign={"center"}
          width="100%"
          cssClass={"confirm-mail-text"}
          textTransform={"none"}
        >
          {t("remove-card-dialog")}
        </TGText>
        <TGButton
          margin={"0 0 35px 0"}
          onButtonClick={confirmHandler}
          backgroundColor={"#EC4E2C"}
          backgroundActiveColor={"var(--tg-error)"}
        >
          <TGText
            color={"var(--tg-white)"}
            fontSize={18}
            fontWeight="semibold"
          >
            {t("remove-card")}
          </TGText>
        </TGButton>
      </div>
    </IonModal>
  );
}

export default CardActionModal
