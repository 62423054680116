import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {handleStoryViewed} from "../../helpers";
import {setCurrentStoryIndex, setIsShowStories} from "../../redux/slices/storiesSlice";
import ReactStories from "react-insta-stories";
import {closeOutline} from "ionicons/icons";
import {IonIcon} from "@ionic/react";

const Stories = () => {
    const {stories, isShowStories, currentStoryIndex,viewed} = useSelector(state => state.stories)
    const dispatch = useDispatch()

    const currentIndexOfStory = stories?.findIndex(story => story.id === currentStoryIndex)

    const onNextHandler = () => {
        const nextStoryId = stories[currentIndexOfStory + 1]?.id
        if (nextStoryId) {
            dispatch(setCurrentStoryIndex(nextStoryId))
        } else {
            dispatch(setIsShowStories(false))
        }
    }

    const onPreviousHandler = () => {
        const previousStoryId = stories[currentIndexOfStory - 1]?.id
        dispatch(setCurrentStoryIndex(previousStoryId))
        if (!previousStoryId) {
            dispatch(setIsShowStories(false))
        }
    }

    const onStoryEndHandler = (index, story) => {
        if (!isShowStories) return
        handleStoryViewed(story.id)

        if (index === stories.length - 1) {
            dispatch(setIsShowStories(false))
            dispatch(setCurrentStoryIndex(null))
        }
    }

    const onStoryStartHandler = (index, story) => {
        if (!isShowStories) return
        handleStoryViewed(story.id)
    }


    return (
        <ReactStories
            loop={true}
            onStoryStart={onStoryStartHandler}
            defaultInterval={2500}
            stories={stories}
            storyContainerStyles={{
                paddingTop: "var(--ion-safe-area-top, 0)",
                position: 'absolute',
                zIndex: 9999,
                transform: isShowStories ? "translateY(0)" : "translateY(100vh)",
                transition: "transform 0.3s ease",
                left: 0,
                top: 0
            }}
            storyStyles={{
                objectFit: "cover",
                height: "100%",
                width: "100vw",
            }}
            header={StoriesHeader}
            currentIndex={currentIndexOfStory}
            onStoryEnd={onStoryEndHandler}
            isPaused={currentIndexOfStory !== 0 && currentStoryIndex === stories.length - 1}
            width={"100%"}
            height={"100%"}
            onPrevious={onPreviousHandler}
            onNext={onNextHandler}
        />
    );
};

const StoriesHeader = (story) => {
    const dispatch = useDispatch();
    const storyHeaderElement = document.querySelector('.StoryHeader');
    const parentElement = storyHeaderElement?.parentElement;

    if (parentElement) {
        parentElement.style.zIndex = 9999;
        parentElement.style.width = 'calc(100% - 24px)';
    }

    return (
        <div className={"StoryHeader"}>
            <div className={"StoryHeaderImageWrapper"}>
                <img src={story.img} alt="story-header"/>
            </div>
            <span className={"StoryHeaderText"}>{story.heading}</span>
            <IonIcon
                className={"StoryHeaderCloseButton"}
                onClick={() => dispatch(setIsShowStories(false))}
                icon={closeOutline}
                size={"large"}
                color={"light"}
            />
        </div>
    )
}

export default Stories;