import React from 'react';
import {TGIcon, TGText} from "../TGElements";
import {useHistory} from "react-router-dom";

const TicketItemForParking = ({ ticket, isDetail=false }) => {
    const history = useHistory()
    return (
        <div className="TicketItem bg-active" onClick={() => !isDetail && history.push(`/menu/support/tickets/${ticket.id}`)}>
            <div className="TicketInner">
                <div className="TicketShortInfo">
                    <TGText fontWeight={500}>{ticket.subject}</TGText>
                </div>
                <div className="TicketSecondLine">
                    <div className="TicketStatus">
                        <span style={{background: ticket.status.color}}/>
                        <TGText fontSize={14}>{ticket.status.name}</TGText>
                    </div>
                </div>
            </div>
            {!isDetail && <TGIcon name="right-arrow" width={16} height={24}/>}
        </div>
    );
};

export default TicketItemForParking;