import React from 'react';
import {Notification} from "./index";
import {format, isToday} from "date-fns";
import {useTranslation} from "react-i18next";
import {TGText} from "../TGElements";

const NotificationGroup = ({notification}) => {
  const {t} = useTranslation()
  const date = notification[0]
  const notifications = notification[1]

  const today = isToday(new Date(date))

  return (
    <div className={"NotificationsGroupWrapper"}>
      <TGText fontSize={14} fontWeight={600} className="NotificationGroupDate">
        {today ? t('today') : format(new Date(date), 'dd.MM.yyyy')}
      </TGText>
      {notifications.map((notification, index) => (
        <Notification key={index} notification={notification} />
      ))}
    </div>
  );
};

export default NotificationGroup;