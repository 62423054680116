import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import Balance from "../components/Wallet/balance";
import {TGPage} from "../components";
import Transactions from "../components/Wallet/transactions";
import {api, env, getWalletBalance} from "../helpers";
import WalletIntroductions from "../components/Wallet/walletIntroductions";
import {useIonRouter, useIonViewDidEnter} from "@ionic/react";
import {WalletInfo} from "../assets/wallet";

const Wallet = () => {
    const {t} = useTranslation()
    const {push} = useIonRouter()
    const [transactionHistory, setTransactionHistory] = useState(null);

    const getTransactions = () => {
        api.getWalletTransactionHistory().then(({data}) => {
            if (data.success) {
                setTransactionHistory(data.data)
            }
        })
    }

    useIonViewDidEnter(() => {
        getTransactions()
        getWalletBalance()
    })

    const goToWalletWebPAge = () => {
        window.open(env('WEB_URL') + 'cuzdan', '_blank')
    }

    const goToWebPrivacyPolicy = (e) => {
        if (e.target.tagName === 'B') {
            e.preventDefault()
            window.open(env('WEB_URL') + 'terms', '_blank')
        }
    }

    return (
        <TGPage
            title={t("wallet")}
            scrollY={false}
            backButton={true}
            onBack={() => push("/menu",'back','pop')}
        >

            <div className={"Wallet"}>
                <Balance/>
                <button onClick={() => push('/menu/wallet/add-balance')} className={"AddBalanceButton"}>{t("add-balance")}</button>
                <div style={{marginTop: 30}}></div>
                {transactionHistory && transactionHistory.length ?
                    <Transactions transactions={transactionHistory}/>
                    :
                    <WalletIntroductions/>
                }

                <div
                    onClick={goToWebPrivacyPolicy}
                    className="WalletPrivacyAndPolicy"
                    dangerouslySetInnerHTML={{__html:t('wallet-terms')}}
                />

                <div className={"WalletInfoContainer"}>
                    <img
                        width={'auto'}
                        height={77}
                        src={WalletInfo}
                        alt={"wallet-info"}
                    />
                    <div className={'WalletInfoTextContainer'}>
                        <div>{t('wallet-info-text')}</div>
                        <span onClick={goToWalletWebPAge}>{t('wallet-info-btn-text')}</span>
                    </div>
                </div>
            </div>
        </TGPage>
    )
}

export default Wallet