// Ionic 
import { IonLoading } from '@ionic/react';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';

const TGLoader = () => {
    const {t} = useTranslation()
    const {loading} = useSelector((state) => state.global)
    return loading.status ? (
          <IonLoading
          isOpen={loading.status}
          message={t(loading.message)+"..."}
          duration={2000}
          />
    ) : null;
}

export default TGLoader;
