import React from 'react';
import {t} from "i18next";
import {TicketForParkingForm, TGPage, TGText} from "../components";
import {useIonViewDidEnter} from "@ionic/react";
import {api} from "../helpers";
import {setAllParks} from "../redux/slices/membershipSlice";
import {useDispatch} from "react-redux";
import TicketListForParking from "../components/TicketForParking/TicketListForParking";

const TicketForParking = () => {
    const dispatch = useDispatch()

    useIonViewDidEnter(()=>{
        api.getParkList().then((r) => {
            r.data?.data && dispatch(setAllParks(r.data.data))
        });
    })

    return (
        <TGPage
            title={t('park-membership')}
            scrollY={true}
            backButton={true}
            cssClass={'TicketForParking'}
        >
            <TicketForParkingForm/>
        </TGPage>
    );
};

export default TicketForParking;