import React from 'react';
import {useIonViewDidEnter} from "@ionic/react";
import {getTicketsForParking} from "../../helpers";
import TicketItemForParking from "./TicketItemForParking";

const TicketListForParking = () => {
    const [tickets, setTickets] = React.useState([])

    useIonViewDidEnter(()=>{
        getTicketsForParking()
            .then(res => setTickets(res))
    })

    return (
        <div className='TicketListForParking'>
            {tickets.map(ticket => <TicketItemForParking key={ticket.id} ticket={ticket}>{ticket.subject}</TicketItemForParking>)}
        </div>
    );
};

export default TicketListForParking;