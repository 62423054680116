import React from 'react';
import {IonButtons, IonHeader, IonModal, IonToolbar} from "@ionic/react";
import {TGButton, TGIcon, TGImg, TGText} from "../TGElements";
import {useDispatch, useSelector} from "react-redux";
import {setParkDetailModal} from "../../redux/slices/parkSlice";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

const ParkDetailModal = () => {
    const open = useSelector(state => state.park.parkDetailModal)
    const detail = useSelector(state => state.park.parkDetail)
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const history = useHistory()

    const closeHandler = () => {
        dispatch(setParkDetailModal(false))
    }

    const goToTicketParking = () => {
        closeHandler()
        history.push('/menu/membership',{parkId: detail.id})
    }

    return (
        <IonModal
            isOpen={open}
            onIonModalWillDismiss={closeHandler}
            breakpoints={[0, 1]}
            initialBreakpoint={1}
            style={{'--height': 'auto'}}
        >

            <IonHeader className="ion-no-border ion-padding-start ion-padding-end">
                <IonToolbar className="TGHeaderToolbar ion-padding-top">
                    <IonButtons slot="end">
                        <TGIcon name={"close-modal"} size={30} onClick={closeHandler}/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <div className={'ion-padding-start ion-text-center ion-padding-end CardActionModal'}>
                <TGText
                    color="var(--tg-primary-text)"
                    fontSize={18}
                    fontWeight="semibold"
                    textAlign="center"
                    width="100%"
                    margin={"0 0 17px 0"}
                >
                    {detail.name}
                </TGText>

                <TGImg
                    src={detail.pricingImage}
                    width={"100%"}
                    additionalStyles={{
                        padding:'26px 16px'
                    }}
                />

                <TGIcon
                    name={'hgs-park-overview'}
                    width={180}
                    height={41}
                    margin={"0 0 34px 0"}
                />

                <TGButton
                    margin={"0 0 35px 0"}
                    onButtonClick={goToTicketParking}
                    backgroundColor={"var(--tg-dark)"}
                    backgroundActiveColor={"var(--tg-black-active)"}
                >
                    <TGText
                        color={"var(--tg-white)"}
                        fontSize={16}
                        fontWeight="medium"
                    >
                        {t("add-subscription")}
                    </TGText>
                </TGButton>
            </div>
        </IonModal>
    );
};

export default ParkDetailModal;