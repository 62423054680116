import {IonApp} from '@ionic/react'
import Routes from './Routes'
import {ConfirmMailModal, TGAlert, TGLoader} from './components'
import React from "react";
import {DevelopmentWarning} from "./components/DevelopmentWarning";
import {SuccessMailVerificationModal} from "./components/SuccessMailVerificationModal";
import {GlobalPushNotificationModal} from "./components/GlobalPushNotificationModal";

const TGApp = () => {
    const app = process.env.REACT_APP_TITLE
    const isDev = process.env.REACT_APP_BACKEND_URL.includes("dev")
    return (
        <IonApp className={`scanner-hide ${app}`}>
            <Routes/>
            <TGLoader/>
            <TGAlert/>
            <ConfirmMailModal />
            <DevelopmentWarning active={isDev}/>
            <SuccessMailVerificationModal />
            <GlobalPushNotificationModal />
        </IonApp>
    )
}

export default TGApp