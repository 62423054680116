import { IonItem, IonTextarea } from '@ionic/react'
import TGText from './TGText'
import {TGErrorText, TGIcon} from "./index";

const TGTextarea = ({placeholder, setValue, error, onInputBlur, counter=false, errorText="", register, rows=10}) => {
  return (
    <>
    <IonItem lines="none" className='TGTextareaContainer'>
      <IonTextarea
        className={`TGTextarea ${error ? "error" : ""}`}
        onIonInput={setValue}
        rows={rows}
        onIonBlur={onInputBlur}
        counter={counter}
        counterFormatter={(inputLength) => `${inputLength}`}
        {...register}
      />
      {placeholder && <label className={`TGTextareaLabel ${error ? "error" : ""}`}>{placeholder}</label>}
    </IonItem>
      {(error && errorText) && <TGErrorText text={errorText}/>}
    </>
  )
}

export default TGTextarea