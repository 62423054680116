import { IonItem, IonSelect, IonSelectOption } from "@ionic/react";
import TGText from "./TGText";
import {t} from 'i18next'

const TGSelect = ({
                    title,
                    optionList = [],
                    placeholder = "Select color",
                    actionSheetOptions,
                    setSelected,
                    selected,
                    translation = false,
                    itemStyle = {}
                  }) => {
  const TGSelectActionSheetOptions = {
    header: "Colors",
    subHeader: "Select your favorite color",
  };
  return (
    <>
      {title && (
        <TGText
          fontSize={14}
          fontWeight={'medium'}
          margin={"21px 0 10px 0"}
        >
          {title}
        </TGText>
      )}
      <IonItem className="TGSelectWrapper" lines="none" style={{...itemStyle}}>
        <IonSelect
          className="TGSelect"
          interface="action-sheet"
          interfaceOptions={actionSheetOptions || TGSelectActionSheetOptions}
          placeholder={placeholder}
          onIonChange={e => setSelected(e.detail.value)}
          value={selected}
        >
          {optionList.map(option => <IonSelectOption className="ion-text-capitalize" key={option.id} value={option.id}>{translation ? t(option.name) : option.name}</IonSelectOption>)}
        </IonSelect>
      </IonItem>
    </>
  );
};

export default TGSelect;
