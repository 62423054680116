import {createSlice} from "@reduxjs/toolkit";
const parkSlice = createSlice({
    name: "parkSlice",
    initialState: {
        parkDetailModal: false,
        parkDetail: {}
    },
    reducers: {
        setParkDetailModal: (state, action) => {
            state.parkDetailModal = action.payload
        },
        setParkDetail: (state, action) => {
            state.parkDetail = action.payload
        },
    },
})

export const {
    setParkDetailModal,
    setParkDetail
} = parkSlice.actions
export default parkSlice.reducer