//Components
import {TGButton, TGIcon, TGText} from '../../../../TGElements';
//Helpers
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const MenuButton = () => {
    const router = useHistory();
    const {t} = useTranslation()
    const {activeSession} = useSelector(state => state.session);
    const isActive = Object.keys(activeSession).length

    return (
        <div className={`TGMapButtonWrapper ${isActive && 'activeSession'}`}>
            <TGButton
              width={48}
              height={48}
              borderRadius={"18px"}
              backgroundColor={"var(--tg-white)"}
              expand="none"
              cssClass="TGMapButton"
              onButtonClick={()=>router.push('/menu')}
              backgroundActiveColor={"var(--tg-item-gray)"}
              additionalStyle={{
                  boxShadow: "0px 2px 4px #0000001F",
              }}
            >
                <TGIcon name="new-menu" width={18} height={12}/>
            </TGButton>
            <TGText fontSize={14} fontWeight={500}>
              {t('menu-text')}
            </TGText>
        </div>
    );
}

export default MenuButton;
