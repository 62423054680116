import React from 'react';
import L from '../../assets/delete-card-left.svg';
import R from '../../assets/delete-card-right.svg';

const DeleteCardAnimation = () => {
  return (
    <div className={'DeleteCardAnimation'}>
      <img className={"left-side"} src={L} alt={"left-card"}/>
      <img className={"right-side"} src={R} alt={"right-card"}/>
    </div>
  );
};

export default DeleteCardAnimation;