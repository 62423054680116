import {Capacitor} from "@capacitor/core";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {getStories, handleStoryViewed} from "../../../../helpers";
import {Stories} from "../../../StoriesComponent";
import {
    setCurrentStoryIndex,
    setIsShowStories,
    sortStoryPreviews
} from "../../../../redux/slices/storiesSlice";

const MapFilterButtons = () => {
    const isNative = Capacitor.isNativePlatform()
    const isIos = Capacitor.getPlatform() === "ios"
    const {viewed, storyPreviews} = useSelector(state => state.stories)
    const {mapLoading} = useSelector(state => state.global)
    const {searchModal} = useSelector(state => state.modal)
    const dispatch = useDispatch()


    useEffect(() => {
        getStories().then((stories) => dispatch(sortStoryPreviews()))
    }, []);


    return (
        <>
            <div className={`MapFilterButtons ${isNative && isIos ? "ios" : ""} ${!mapLoading.status && !searchModal.status ? "active" : ""}`}>
                <div className={"StorySliderContainer"}>
                    <div className={"StorySlider"}>

                        {/*don't viewed stories left side */}
                        {storyPreviews?.length > 0 && storyPreviews?.map((storyPreview, index) => {
                            const isViewed = viewed.includes(storyPreview.id)
                            return (
                                <div key={index} className={`StorySliderItem`}>
                                    <div
                                        onClick={() => {
                                            dispatch(setCurrentStoryIndex(storyPreview.id))
                                            dispatch(setIsShowStories(true))
                                            handleStoryViewed(storyPreview.id)
                                        }}
                                        className={`StorySliderItemImageWrapper ${isViewed && 'viewed'}`}>
                                        <img
                                            width={"100%"}
                                            height={"100%"}
                                            src={storyPreview.photo}
                                            alt="story-preview"
                                        />
                                    </div>
                                    <div className={"StorySliderItemText"}> {storyPreview.name} </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <Stories />
        </>
    );
}

export default MapFilterButtons;
