import {createSlice} from "@reduxjs/toolkit";

export const storiesSlice = createSlice({
    name: "storiesSlice",
    initialState: {
        stories: [{url: ''}], // Array of story objects
        viewed: [], // Array of story ids that have been viewed
        isShowStories: false,
        storyPreviews: [], // Array of story preview objects
        currentStoryIndex: 0
    },
    reducers: {
        setViewed: (state, action) => {
            state.viewed = action.payload
        },
        setStories: (state, action) => {
            state.stories = action.payload
        },
        setIsShowStories: (state, action) => {
            state.isShowStories = action.payload
        },
        setStoryPreviews: (state, action) => {
            state.storyPreviews = action.payload
        },
        setCurrentStoryIndex: (state, action) => {
            state.currentStoryIndex = action.payload
        },
        sortStoryPreviews: (state) => {
            const {viewed} = state
            state.storyPreviews = state.storyPreviews.sort((a, b) => {
                if(viewed?.includes(a.id) && !viewed?.includes(b.id)) return 1
                if(!viewed?.includes(a.id) && viewed?.includes(b.id)) return -1
                return 0
            })
        },
        sortStories: (state) => {
            const {viewed} = state
            state.stories = state.stories.sort((a, b) => {
                if(viewed?.includes(a.id) && !viewed?.includes(b.id)) return 1
                if(!viewed?.includes(a.id) && viewed?.includes(b.id)) return -1
                return 0
            })
        }
    },
})

export const {
    setViewed,
    setStories,
    setIsShowStories,
    setStoryPreviews,
    setCurrentStoryIndex,
    sortStories,
    sortStoryPreviews
} = storiesSlice.actions
export default storiesSlice.reducer