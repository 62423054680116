import {TGIcon, TGText} from '../TGElements';
import { useDispatch } from 'react-redux';
import { setCardActionsModal } from '../../redux/slices/modalSlice';
import TGImg from "../TGElements/TGImg";
import {MasterCard, Visa} from "../../assets/images";
import React from "react";
import {IonItem, IonItemOption, IonItemOptions, IonItemSliding} from "@ionic/react";
import {useDialog} from "../../hooks";
import {setDefaultCard} from "../../helpers";
const Card = ({card}) => {
  const showDialog = useDialog()
  const dispatch = useDispatch()
  
  const handleDeleteCard = () => {
    dispatch(setCardActionsModal({status: true, selectedCard: card}))
  }

  const itemStyle = {
    "--padding-start": "0px",
    "--background": "#fafafa",
    "--padding-end": "0px",
    "--inner-padding-end": "0px"
  }

  const defaultCardHandler = () => {
    if (card.default === 1) return
    const confirmHandler = () => {
      setDefaultCard(card.id)
    }
    showDialog(`make-default-card`, "yes", confirmHandler, "no", ()=>{})
  }

  return (
    <IonItemSliding className="MyCardSlideWrapper ion-color-danger">
      <IonItem onClick={defaultCardHandler} lines={"none"} style={itemStyle} className={`CardWrapper`}>
        <div className={"CardDetail"}>
          <div className={"PaymentMethodsContainerStart"}>
            <TGImg width={41} src={card?.card.brand === "MasterCard" ? MasterCard : Visa}/>
          </div>
          <div className={"PaymentMethodsContainerMiddle"}>
            <TGText fontSize={16} fontWeight={500}>**** **** **** {card?.card?.last4}</TGText>
          </div>
        </div>
        {card.default === 1 ? (
          <TGIcon
            name={"circle-green-tick"}
            width={25}
            height={25}
            style={{marginLeft: "auto"}}
          />
        ) : <div className={"empty-circle"}></div>}
      </IonItem>
      <IonItemOptions side="end">
        <IonItemOption color={"danger"} onClick={handleDeleteCard}>
          <div className={"OptionsItem"}>
            <TGIcon name={"remove-vehicle"} size={21}/>
          </div>
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  )
};

export default Card;
