import React, {useEffect, useRef, useState} from 'react';
import {IonButtons, IonHeader, IonModal, IonToolbar} from "@ionic/react";
import TGIcon from "../TGElements/TGIcon";
import {useDispatch, useSelector} from "react-redux";
import {setGlobalPushModalName} from "../../redux/slices/modalSlice";
import {getContent} from "../../helpers";
import history from "../../helpers/history";


const GlobalPushNotificationModal = () => {
  const dispatch = useDispatch()
  const [isShow, setIsShow] = useState(false)
  const {globalPushModalName} = useSelector(state => state.modal)
  const content = useRef(undefined)

  useEffect(() => {
    if (globalPushModalName) {
      getContent(globalPushModalName).then((res) => {
        if (res) {
          setIsShow(true)
          content.current = res
        }
      })
    }
  }, [globalPushModalName])

  const closeGlobalPushModal = () => {
    setIsShow(false)
    dispatch(setGlobalPushModalName(''))
  }

  const handleContentClick = (e) => {
    if (e.target.tagName === 'BUTTON') {
      e.preventDefault()
      e.stopPropagation()
      closeGlobalPushModal()
      const url = e.target.getAttribute('href')
      if (url.startsWith('http')) {
        return window.open(url, '_blank')
      }
      history.push(url)
    }
  }


  return (
    <IonModal
      isOpen={isShow}
      onDidDismiss={closeGlobalPushModal}
      breakpoints={[0, 1]}
      initialBreakpoint={1}
      style={{'--height': 'auto'}}
      className={"GlobalModal"}
    >

      <IonHeader className="ion-no-border ion-padding-start ion-padding-end">
        <IonToolbar className="TGHeaderToolbar ion-padding-top">
          <IonButtons slot="end">
            <TGIcon name={"close-modal"} size={30} onClick={closeGlobalPushModal}/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <div
        className={'ion-padding-start ion-padding-end ion-text-center GlobalModalContent'}
        dangerouslySetInnerHTML={{__html: content.current}}
        onClick={handleContentClick}
      />
    </IonModal>
  );
};

export default GlobalPushNotificationModal;