import React from 'react';
import {TGIcon, TGText} from "../../TGElements";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import ScanCenterIcon from "./ScanCenterIcon";

const ScanButton = () => {
    const {t} = useTranslation()
    const {activeSession} = useSelector(state => state.session);
    const isActiveSession = Object.keys(activeSession).length;

    return (
        <div className={"TGMapButtonWrapper"}>
            <TGIcon
                cssClass={`anniversaryIcon ${isActiveSession && 'activeSession'}`}
                name="anniversary"
                width={98}
                height={26}
                margin="0 0 15px 0"
                fill="#ce0024"
            />
            <ScanCenterIcon />
            <TGText
                fontSize={16}
                fontWeight={700}
                margin={"0 0 8px"}
                cssClass={`TGMapScanText ${isActiveSession && 'activeSession'}`}
            >
                {t('map-start-charge-btn')}
            </TGText>
        </div>
    );
};

export default ScanButton;