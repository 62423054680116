import { configureStore } from '@reduxjs/toolkit'
import {
  globalSlice,
  userSlice,
  mapSlice,
  modalSlice,
  stationSlice,
  sessionSlice,
  newCarSlice,
  searchSlice,
  deviceSlice,
  configSlice,
  appRateSlice,
  membershipSlice,
  storiesSlice,
  parkSlice
} from "./slices";
//Persist
import CapacitorStorage from 'redux-persist-capacitor';
import { persistCombineReducers, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1";

const PERSIST_CONFIG = {
 key: "root",
 storage: CapacitorStorage,
 stateReconciler: autoMergeLevel1,
 whitelist: ["config", "appRate","user","stories"]
}

const REDUCERS = {
  global: globalSlice,
  user: userSlice,
  map: mapSlice,
  modal: modalSlice,
  station: stationSlice,
  session: sessionSlice,
  newCar: newCarSlice,
  search: searchSlice,
  device: deviceSlice,
  config: configSlice,
  appRate: appRateSlice,
  membership: membershipSlice,
  stories: storiesSlice,
  park: parkSlice
}

export const store = configureStore({
  reducer: persistCombineReducers(PERSIST_CONFIG, REDUCERS),
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });
  }
});

export const persistor = persistStore(store)
