import { useTranslation } from "react-i18next";
import { TGIcon, TGText } from "../TGElements";
import { formatDate } from "../../helpers";
import { useHistory } from "react-router";

const InvoiceItem = ({ invoice }) => {
  const { t } = useTranslation();
  const history = useHistory()
  const icon =
    invoice.category_id === 1
      ? "charge"
      : invoice.category_id === 2
      ? "park"
      : "park-charge";
  return (
    <div className="InvoiceItem" onClick={() => history.push(`/menu/payments/invoices/detail/${invoice.id}`, {
      invoice
    })}>
      <div>
        <TGIcon name={icon} size={50} fill={`var(--tg-${icon})`}/>
        <div>
          <div>
            <TGText
              fontWeight={"regular"}
              fontSize={14}
              color={"var(--tg-primary-text)"}
            >
              {formatDate(invoice.created_at)}
            </TGText>
            <TGText
              fontWeight={"regular"}
              fontSize={14}
              color={"var(--tg-secondary-text)"}
            >
              {formatDate(invoice.created_at, "time")}
            </TGText>
          </div>
          <TGText
            margin={"10px 0 0 0"}
            fontWeight={"semibold"}
            fontSize={15}
            color={"var(--tg-primary-text)"}
            width={'200px'}
            wrapWithDots={true}
          >
            {invoice.name}
          </TGText>
          <TGText
            margin={"6px 0 0 0"}
            fontWeight={"regular"}
            fontSize={12}
            color={"var(--tg-primary-text)"}
          >
            {t("invoice-no")}: #{invoice.id}
          </TGText>
          <TGText
            margin={"6px 0 0 0"}
            fontWeight={"regular"}
            fontSize={12}
            color={"var(--tg-primary-text)"}
          >
            {t("status")}: {invoice.status_name}
          </TGText>
        </div>
      </div>
      <TGIcon name="right-arrow"/>
    </div>
  );
};

export default InvoiceItem;
