import React from 'react';
import {TGIcon} from "./index";
import TGText from "./TGText";

const TGErrorText = ({text}) => {
    return (
        <div style={styles.ErrorTextWrapper}>
            <TGIcon name={"error-input"} width={13} height={13}/>
            <TGText
                color={"var(--tg-error)"}
                fontSize={12}
                fontWeight={"medium"}
                textTransform={"unset"}
            >
                {text}
            </TGText>
        </div>
    );
};

const styles = {
    ErrorTextWrapper: {
        display: "flex",
        alignItems: "center",
        gap: 5,
        marginTop: 7
    }
}

export default TGErrorText;