import React, {useEffect} from 'react';
import {TGButton, TGErrorText, TGInput, TGSelect, TGText, TGTextarea} from "../TGElements";
import {t} from "i18next";
import {useForm} from "react-hook-form";
import {sendContactForm} from "../../helpers";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import TicketListForParking from "./TicketListForParking";

const TicketForParkingForm = () => {
    const {allParks} = useSelector(state => state.membership)
    const [selectedPark, setSelectedPark] = React.useState(null)
    const history = useHistory()
    const state = history.location.state
    const {
        register,
        handleSubmit,
        formState: {errors,isSubmitted},
    } = useForm();

    useEffect(()=>{
        if (state?.parkId) {
            setSelectedPark(state.parkId)
        }
    },[])


    const onSubmit = async data => {
        const findParkName = allParks.find(park => park.id === selectedPark)
        const title = `ABONELIK - ${findParkName?.name}`
        const upperCasePlate = data.plate.toUpperCase()
        const message = `Plaka: <b>${upperCasePlate}</b> </br></br> ${data.message}`
        data = {title, message, department_slug: "park_abonelik"}
        const {success} = await sendContactForm(data)
        if (success) {
            history.goBack()
        }
    }

    return (
        <form className='TicketForm' onSubmit={handleSubmit(onSubmit)}>
            <TicketListForParking/>
            <TGSelect
                optionList={allParks}
                placeholder={t("choose-park-point")}
                actionSheetOptions={{
                    header: t("choose-park-point"),
                    subHeader: ''
                }}
                setSelected={setSelectedPark}
                selected={selectedPark}
                translation={true}
                itemStyle={{
                    "--background": "var(--tg-white)",
                    borderRadius: "10px",
                    border: "1px solid var(--tg-gray)",
                    margin: "25px 0 10px 0",
                    padding: "0 8px",
                    borderColor: !selectedPark && isSubmitted ? "var(--tg-error)" : "var(--tg-gray)",
                    color: !selectedPark && isSubmitted ? "var(--tg-error)" : "var(--tg-black)",
                    fontWeight: !selectedPark && isSubmitted ? "500": "400",
                }}
            />
            {!selectedPark && isSubmitted && <TGErrorText text={t("please-choose-park-point")} />}

            <TGInput
                label={t("plate")}
                borderRadius={10}
                maxlength={8}
                margin={"0 0 0.4rem 0"}
                error={!!errors.plate}
                errorText={errors.plate?.message}
                register={register("plate", {
                    required: t(`valid-plate`),
                    minLength: {value: 3, message: t("min-length-title", {length: 3})}
                })}
                additionalStyles={{
                    textTransform: "uppercase"
                }}
            />
            <TGTextarea
                rows={4}
                placeholder={t("message")}
                counter={true}
                error={!!errors.message}
                errorText={errors.message?.message}
                register={register("message", {
                    required: t(`please-enter-valid-message`),
                    minLength: {value: 5, message: t("min-length-message", {length: 5})},
                })}
            />
            <TGButton type="submit"
                      additionalStyle={{
                          marginTop: "auto",
                      }}
            >
                <TGText color={"var(--tg-white)"}>
                    {t("membership-request")}
                </TGText>
            </TGButton>
        </form>
    );
};

export default TicketForParkingForm;