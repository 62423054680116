import {TGButton, TGIcon, TGText} from '../../../../TGElements';
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const CampaignButton = () => {
    const {t} = useTranslation()
    const history = useHistory()
    const {activeSession} = useSelector(state => state.session);
    const isActive = Object.keys(activeSession).length;

    return (
        <div className={`TGMapButtonWrapper ${isActive && 'activeSession'}`}>
            <TGButton
                width={48}
                height={48}
                borderRadius={"18px"}
                backgroundColor={"var(--tg-park)"}
                expand="none"
                cssClass="TGMapButton"
                onButtonClick={() => history.push("/menu/membership")}
                backgroundActiveColor={"var(--tg-park)"}
                additionalStyle={{
                    color: 'var(--tg-secondary)',
                }}
            >
                <TGIcon name="parking" width={24} height={24}/>
            </TGButton>
            <TGText fontSize={14} fontWeight={500}>
                {t('subscription')}
            </TGText>
        </div>
    );
}

export default CampaignButton;
