//React
import {useEffect} from 'react'
//Components
import Card from './Card'
import {NoEntries} from '..'
//Redux
import {useSelector} from 'react-redux'
//Helpers
import {getPaymentMethods} from '../../helpers'
import {useLocation} from "react-router";
import {useIonViewDidEnter} from "@ionic/react";

const CardList = ({setCardActionModalOpen}) => {
    const location = useLocation()
    const {pathname} = location
    const {paymentMethods} = useSelector(state => state.user)

    useIonViewDidEnter(() => {
        if (pathname === '/menu/payments/registered-cards') {
            getPaymentMethods()
        }
    }, [])

    return (
        <div className='CardList'>
            {paymentMethods?.length ? paymentMethods.map((card) => (
                <Card card={card} key={card.id} setCardActionModalOpen={setCardActionModalOpen}/>
            )) : <NoEntries path={"registered-cards"}/>}
        </div>
    )
}

export default CardList