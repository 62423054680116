// Components
import {NoEntries, NotificationGroup} from '../components'
//Redux
import { useSelector } from 'react-redux'
//Translation
import { useTranslation } from 'react-i18next'
import {api, getNotifications, deleteNotification} from '../helpers'
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewWillEnter
} from "@ionic/react";
import {TGBackButton} from "../components/TGElements";

const Notifications = () => {
  const {t} = useTranslation()
  const {notifications} = useSelector(state => state.user)

  useIonViewWillEnter(()=>{
    getNotifications()
  })

  useIonViewDidEnter(()=>{
    const res = api.markAllRead()
  })

  const ClearAll = () => (
    <IonButtons slot="end">
      <div
        className={"clear-all-button"}
        onClick={()=>deleteNotification()}
      >
        {t("clear")}
      </div>
    </IonButtons>
  )

  return (

    <IonPage className={"NotificationsPage"}>
      <IonHeader className={"ion-no-border"}>
        <IonToolbar className={"TGPageHeader"}>
          <IonButtons slot="start">
            <TGBackButton/>
          </IonButtons>
          <IonTitle className={"ion-text-capitalize"}>{t("notifications")}</IonTitle>
          {notifications?.length ? <ClearAll/> : undefined}
        </IonToolbar>
      </IonHeader>

      <IonContent
        scrollY={true}
        className={"TGPageContent ion-padding-start ion-padding-end"}
        fullscreen={true}
      >
        {notifications?.length ? (
          notifications.map((notification,index) => <NotificationGroup key={index} notification={notification} />)
        ) : <NoEntries path={'notifications'} /> }
      </IonContent>

    </IonPage>
  );
}

export default Notifications