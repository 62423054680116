import {TGText} from "../TGElements";
import {format} from "date-fns";
import {redirectUrl} from "../../helpers";

const Notification = ({notification}) => {

  const clickHandler = () => {
    if (notification.url) {
      redirectUrl(notification.url)
    }
  }

  return (
    <div className="Notification" onClick={clickHandler}>
      <div className={`circle-icon ${!notification.readed && 'active'}`}/>
      <div className={"NotificationTextWrapper"}>
        <div className={"NotificationTitleWrapper"}>
          <TGText fontWeight={600} fontSize={14}>
            {notification.title}
          </TGText>
          <TGText color={'var(--tg-gray-line)'} fontWeight={500} fontSize={13}>
            {format(new Date(notification.created_at), 'HH:mm')}
          </TGText>
        </div>
        <TGText
          color={'var(--tg-secondary-text)'}
          fontWeight={500}
          fontSize={14}
          textTransform={"unset"}
        >
          {notification.message}
        </TGText>
      </div>
    </div>
  );
}

export default Notification;
